<template>
	<div class="Shop">
		
		<el-dialog :visible="dialogImageUrl != ''" :before-close="priviewBeforeClose">
		  <img width="100%" :src="dialogImageUrl" alt="">
		</el-dialog>
		<div class="Top">
			<div class="Left">
				店铺申请详情
				{{ShopApply != null ? '('+ShopApply.StatusName+')':''}}
			</div>
			<div class="Right">
				<span>
					<el-button type="primary" icon="el-icon-arrow-left" @click="$router.go(-1)">返回店铺申请列表</el-button>
				</span>
			</div>
		</div>
		
		<div class="List" v-if="ShopApply != null">
			
			<div class="One">
				<li>
					<span>店铺申请编号</span>
					<em>
						{{ShopApply.Id}}
					</em>
				</li>
				
				<li>
					<span>店铺类型</span>
					<em>
						<el-select :disabled="IsEditDisable" v-model="ShopApply.Type" placeholder="请选择">
							<el-option :label="'企业店铺'" :value="'company'"></el-option>
							<el-option :label="'个人店铺'" :value="'person'"></el-option>
						</el-select>
					</em>
				</li>
				
				
				
				<li>
					<span>店铺名</span>
					<em>
						<i>
						<el-input class="Width200" :disabled="IsEditDisable" v-model="ShopApply.Name"></el-input>
						</i>
					</em>
				</li>
				
				<li>
					<span>
						{{ShopApply.Type == 'company' ? '企业名称':'姓名'}}
					</span>
					<em>
						<i>
						<el-input  class="Width200" :disabled="IsEditDisable" v-model="ShopApply.CredentialsName"></el-input>
						</i>
					</em>
				</li>
				
				<li>
					<span>
						{{ShopApply.Type == 'company' ? '营业执照编号':'身份证号'}}
					</span>
					<em>
						<i>
						<el-input   class="Width200" :disabled="IsEditDisable" v-model="ShopApply.CredentialsId"></el-input>
						</i>
					</em>
				</li>
				
				<li>
					<span>
						{{ShopApply.Type == 'company' ? '营业执照':'身份证正面'}}
					</span>
					<em>
						 <img @click="dialogImageUrl = ShopApply.CredentialsImg"  style="height: 120px;" v-if="ShopApply.CredentialsImg != ''" :src="ShopApply.CredentialsImg" />
					</em>
				</li>
				
				<li v-if="ShopApply.Type == 'person'">
					<span>
						身份证反面
					</span>
					<em>
						 <img @click="dialogImageUrl = ShopApply.CredentialsImg2" style="height: 120px;" v-if="ShopApply.CredentialsImg2 != ''" :src="ShopApply.CredentialsImg2" />
					</em>
				</li>
				
				
				<li>
					<span>
						证件地址
					</span>
					<em>
						<i style="width: 600px;">
							<el-input  :disabled="IsEditDisable" v-model="ShopApply.CredentialsAddress"></el-input>
						</i>
					</em>
				</li>
				
				<li>
					<span>
						联系地址
					</span>
					<em>
						<i style="width: 600px;">
							<el-input  :disabled="IsEditDisable" v-model="ShopApply.ContactAddress"></el-input>
						</i>
					</em>
				</li>
				
				
				<li>
					<span>
						联系人
					</span>
					<em>
						<i>
						<el-input class="Width200" :disabled="IsEditDisable" v-model="ShopApply.Contacts"></el-input>
						</i>
					</em>
				</li>
				
				<li>
					<span>
						联系人手机
					</span>
					<em>
						<i>
						<el-input class="Width200" :disabled="IsEditDisable" v-model="ShopApply.ContactMobile"></el-input>
						</i>
					</em>
				</li>
				
				<li>
					<span>创建时间</span>
					<em>
						{{ShopApply.CreatedAt}}
					</em>
				</li>
				
				<li>
					<span>最后修改时间</span>
					<em>
						{{ShopApply.UpdatedAt}}
					</em>
				</li>
				
				<li v-if="ShopApply.Status == 50">
					<span></span>
					<em>
						<el-button @click="UpdateShopApply(70)" type="success">通过审核</el-button>
						<el-button @click="UpdateShopApply(60)" type="danger">驳回开店申请</el-button>
					</em>
				</li>
			</div>
		</div>
		
		
	</div>
</template>

<script>
	import {Select,Option,Dialog,Upload} from 'element-ui'
	export default {
	  name: 'ShopApplyInfo',
	  props: {
	  },
	  data() {
	      return {
			  ShopApply:null,
			  IsEditDisable:true,
			  dialogImageUrl:''
	      }
	  },
	  components: {
		'el-select':Select,
		'el-option':Option,
		'el-dialog':Dialog,
		'el-upload':Upload,
	  },
	  created() {
	  	if(this.$route.params.Id == undefined){
			this.$message('缺少用户身份ID，页面无法工作')
			return
		}
		this.GetShopApply(this.$route.params.Id)
	  },
	  methods:{
		  priviewBeforeClose(){
			  this.dialogImageUrl = ''
		  },
		    GetShopApply(_id){
		  		let that = this
		  		let data = {Service:'Shop',Class: 'ShopApply',Action: 'Get',Id:_id,}
		  		this.$post(that.$store.getters.getApiHost,data)
		  		.then((res) => {
		  			
		  			if(res.ErrorId != 0){
		  				that.$message(res.Msg)
		  				return
		  			}
		  			that.ShopApply = res.Data
		  			
		  		})
		    },
			UpdateShopApply(_status){
				let that = this
				let data = {Service:'Shop',Class: 'ShopApply',Action: 'Update',Id:that.ShopApply.Id,Status:_status}
				this.$post(that.$store.getters.getApiHost,data)
				.then((res) => {
					that.$message(res.Msg)
					if(res.ErrorId == 0){
						that.GetShopApply(that.ShopApply.Id)
					}
				})
			},
	  }
	}
</script>

<style scoped>
.Shop{
	background-color: #FFFFFF;
	margin: 20px;
	padding: 20px;
}
.Shop .Top{
	display: flex;
	align-items: center;
}
.Shop .Top .Left{
	
}
.Shop .Top .Right{
	flex: 1;
	text-align: right;
	color: #999999;
}

.Shop .List{
	margin-top: 20px;	
}
.Shop .List .One{
	border: 1px solid rgba(0,0,0,0.04);
	padding: 20px;
	margin-bottom: 20px;
}
.Shop .List .One li{
	display: flex;
	line-height: 20px;
	padding: 10px 0px;
	align-items: center;
}
.Shop .List .One li span{
	width: 140px;
	color: rgba(0,0,0,0.3);
}
.Shop .List .One li  em{
	flex: 1;
}
.Shop .List .One li i{
	font-style: normal;
	color: rgba(0,0,0,0.2);
}
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 64px;
    height: 64;
    line-height: 64px;
    text-align: center;
  }
  .avatar {
    width: 64px;
    height: 64px;
    display: block;
  }
  .Width200{
	  width: 200px;
  }
</style>
